<template>
  <div>
    <div v-for="(item, index) in notifications" :key="index + 'notification'">
      <b-card>
        <b-media>
          <template #aside>
            <b-avatar
              class="badge-light-primary"
              size="36"
              :variant="item.type"
            >
              <feather-icon icon="BellIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder text-black">
              {{ item.notification_heading }}
            </span>
            <span style="float: right">
              {{ getDate(item.createdAt) }}
            </span>
          </p>
          <span class="notification-text">
            {{ item.notification_text }}
          </span>
        </b-media>
      </b-card>
    </div>
    <div>
      <b-button @click="loadMore"> load more </b-button>
    </div>
    <br />
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  BCard,
  BCardText,
  BMedia,
  BAvatar,
  BLink,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import * as MasterServices from "@/apiServices/MastersServices";

export default {
  components: {
    BCard,
    BCardText,
    BMedia,
    BAvatar,
    BLink,
    BFormCheckbox,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      limit: 10,
      notifications: [],
    };
  },
  methods: {
    loadMore() {
      this.limit = this.limit + 10;
      this.getAllNotifications();
    },
    async getAllNotifications() {
      try {
        const response = await MasterServices.getAllNotifications({
          limit: this.limit,
        });
        if (response.data.status) {
          this.notifications = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting all notifications  ", err);
      }
    },
    getDate(created_at) {
      if (created_at) {
        let date = new Date(created_at);
        return date.toDateString() + " - " + date.toLocaleTimeString();
      }
    },
  },
  beforeMount() {
    this.getAllNotifications();
  },
};
</script>

<style>
.badge-light-primary {
  background-color: #e5d5bc !important;
}
</style>
